import { goPixieContainerElementId, goPixieDrawerElementId } from "../constants/common";

function isElementVisible(element: HTMLElement, contentWindow?: Window): boolean {
  const style = (contentWindow || window).getComputedStyle(element);
  return style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0';
}

function isNaturallyInvisible(element: HTMLElement): boolean {
  const invisibleTags = ['meta', 'script', 'style', 'noscript', 'link', 'title', 'head'];
  return invisibleTags.includes(element.tagName.toLowerCase());
}

function isMeaninglessElement(element: HTMLElement): boolean {
  const meaninglessTags = ['br', 'svg', 'path', 'rect', 'circle', 'line', 'polygon', 'polyline', 'hr', 'input', 'textarea', 'button', 'select', 'option', 'div', 'span', 'p'];
  return (
    meaninglessTags.includes(element.tagName.toLowerCase()) &&
    element.innerHTML.trim() === '' &&
    !element.hasAttributes()
  );
}

function cleanAttributes(element: HTMLElement): void {
  // TODO the cleaning has been moved to server
  // Remove unnecessary attributes
  // including class to help LLM determine selector, need further evaluation on potential token saving
  // for (const attr of Array.from(element.attributes)) {
  //   if (!['id', 'href', 'src', 'contenteditable', "class"].includes(attr.name)) {
  //     element.removeAttribute(attr.name);
  //   }
  // }
}

// return a deep copy of the DOM tree with invisible elements removed
function traverseAndClean(
  originalNode: Node,
  contentWindow?: Window,
  beforeCaptureNode?: (node: Node) => void,
  onCaptureNode?: (node: Node) => void,
): Node | null {
  beforeCaptureNode?.(originalNode);
  const copiedNode = originalNode.cloneNode(false);
  if (originalNode.nodeType === Node.COMMENT_NODE) {
    return null;
  }
  // TODO whitespace might help LLM performance, need to see whether it's better to compress whitespaces than remove
  else if (originalNode.nodeType === Node.TEXT_NODE) {
    const text = originalNode.textContent?.trim();
    if (!text) {
      return null;
    }
    copiedNode.textContent = text; // Trim the text node
  }
  else if (originalNode.nodeType === Node.ELEMENT_NODE) {
    const originalElement = originalNode as HTMLElement;
    if ([goPixieContainerElementId, goPixieDrawerElementId].includes(originalElement.id)) {
      return null;
    }
    if (!isElementVisible(originalElement, contentWindow) || isNaturallyInvisible(originalElement)) {
      return null;
    }
    const childNodes = Array.from(originalNode.childNodes);
    for (const child of childNodes) {
      const copiedChild = traverseAndClean(child, contentWindow, beforeCaptureNode, onCaptureNode);
      if (copiedChild) {
        copiedNode.appendChild(copiedChild);
      }
    }
    cleanAttributes(copiedNode as HTMLElement);
    if (isMeaninglessElement(copiedNode as HTMLElement)) {
      return null;
    }
  }
  onCaptureNode?.(originalNode);
  return copiedNode;
}

function filterDOM(
  element: HTMLElement,
  contentWindow?: Window,
  beforeCaptureNode?: (node: Node) => void,
  onCaptureNode?: (node: Node) => void,
): HTMLElement {
  const copied = traverseAndClean(element, contentWindow, beforeCaptureNode, onCaptureNode);
  return (copied as HTMLElement | null) || document.createElement(element.tagName);
}

export async function captureWebContent(
  elem?: HTMLElement,
  // window is used to resolve element styles
  contentWindow?: Window,
  // event handlers will be passed the ORIGINAL node that's being processed
  // rather than the copied node that'd be returned
  beforeCaptureNode?: (node: Node) => void,
  onCaptureNode?: (node: Node) => void,
): Promise<string> {
  return new Promise<string>((resolve) => {
    const capture = () => {
      elem = elem || document.documentElement;
      const filteredDOM = filterDOM(elem, contentWindow, beforeCaptureNode, onCaptureNode);
      window.removeEventListener('load', capture);
      console.log(filteredDOM.outerHTML);
      resolve(filteredDOM.outerHTML);
    };

    if (document.readyState === 'complete') {
      // Document is already loaded
      capture();
    } else {
      // Wait for the load event
      window.addEventListener('load', capture);
    }
  });
}
