import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { AppRunError, ClientState } from '../types/ClientState';
import { TAppDebugInfo, TLayout, TMessage, TStatusMessage, TUserInputRequirement } from '../../generated/gql/graphql';

export type ClientStateWithHelpers = ClientState & {
  setClientId: (clientId: string | undefined) => void,
  setFlowId: (flowId: string | undefined) => void,
  setInConversation: (conversationOpen: boolean) => void,
  clearUnreadCount: () => void,
  increaseUnreadCount: (increaseBy?: number) => void,

  setInitializing: (initializing: boolean) => void,
  setServerInProgress: (serverInProgress: boolean) => void,
  setInputRequirement: (inputRequirement: TUserInputRequirement | undefined) => void,
  setError: (error: AppRunError | undefined) => void,
  setCompleted: (completed: boolean) => void,
  addDebugLog: (debugLog: TAppDebugInfo) => void,
  addMessage: (messages: TMessage | TStatusMessage) => void,
  resetClient: () => void,
}

export const initialClientState: ClientState = {
  clientId: undefined,
  flowId: undefined,
  inConversation: false,
  unreadCount: 0,
  initializing: true,
  serverInProgress: false,
  inputRequirement: undefined,
  messages: [],
  debugLogs: [],
  error: undefined,
  completed: false,
}

export const useClientStore = create<ClientStateWithHelpers>()(
  devtools(subscribeWithSelector(immer((set, get) => ({
    ...initialClientState,
    setClientId: clientId => {
      set(state => {
        state.clientId = clientId
      });
    },
    setFlowId: flowId => {
      set(state => {
        resetClientState(state);
        state.flowId = flowId;
      });
    },
    setInConversation: inConversation => {
      set(state => {
        state.inConversation = inConversation
      });
    },
    clearUnreadCount: () => {
      set(state => {
        state.unreadCount = 0
      });
    },
    increaseUnreadCount: (increaseBy = 1) => {
      set(state => {
        state.unreadCount += increaseBy
      });
    },
    setInitializing: initializing => {
      set(state => {
        state.initializing = initializing
      });
    },
    setServerInProgress: serverInProgress => {
      set(state => {
        if (!serverInProgress) {
          removeTransitionalStatusMessage(state);
        }
        state.serverInProgress = serverInProgress;
      });
    },
    setInputRequirement: inputRequirement => {
      set(state => {
        removeTransitionalStatusMessage(state);
        state.inputRequirement = inputRequirement;
      });
    },
    setError: error => {
      set(state => {
        removeTransitionalStatusMessage(state);
        // Cast to any to bypass readonly issue
        state.error = error as any;
      });
    },
    setCompleted: completed => {
      set(state => {
        removeTransitionalStatusMessage(state);
        state.completed = completed;
      });
    },
    addDebugLog: debugLog => {
      set(state => {
        state.debugLogs.push(debugLog)
      });
    },
    addMessage: message => {
      set(state => {
        removeTransitionalStatusMessage(state);
        state.messages.push(message);
      });
    },
    resetClient: () => {
      set(resetClientState);
    },
  }))))
)


function resetClientState(state: ClientState) {
  const flowId = state.flowId;
  for (const key in initialClientState) {
    state[key] = initialClientState[key];
  }
  // Preserve flowId
  state.flowId = flowId;
}


function removeTransitionalStatusMessage(state: ClientState) {
  const lastMessage = state.messages[state.messages.length - 1];
  if (lastMessage && lastMessage.__typename === 'TStatusMessage' && !lastMessage.persistent) {
    state.messages.pop();
  }
}
