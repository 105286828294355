import React, { useEffect, useRef, useState } from 'react';
import { Box, Fab, Popover, useTheme, alpha, Badge, IconButton, styled, CircularProgress, SxProps } from '@mui/material';
import { Property } from 'csstype';
import { ResponsiveStyleValue } from '@mui/system';
import { useApolloClient } from '@apollo/client';
import { Pulse } from '../../../../components/animations';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import { useClientStore } from '../../../../hooks/ClientState';
import { useShallow } from 'zustand/react/shallow';
import { goPixieDrawerElementId } from '../../../../constants/common';
import Draggable from 'react-draggable';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { use100vh } from 'react-div-100vh';
import { useUserAndWorkspaceStore } from '../../../../hooks/UserAndWorkspaceStore';
import { useStyling } from '../../../../components/customization/customization-provider';
import Lottie from 'lottie-react';

// TODO use debug app based on client settings
function ChatAppWithContainer(props: {
  children: React.ReactElement,
  onClose: () => void,
}): React.ReactElement {
  // const [
  //   loadStaticTypes,
  //   loadApp,
  // ] = useEditorStore(state => [
  //   state.graphql.loadStaticTypes,
  //   state.graphql.loadApp,
  // ]);
  // const client = useApolloClient();
  // const [appLoaded, setAppLoaded] = useState(false);

  // useEffect(() => {
  //   chrome.tabs.query({ active: true, currentWindow: true }, tabs => {
  //     const tab = tabs[0];
  //     if (tab) {
  //       setTabId(tab.id);
  //     }
  //   });
  // }, []);
  // useEffect(() => {
  //   loadStaticTypes(client);
  // }, []);

  // useEffect(() => {
  //   if (flowId) {
  //     loadApp(client, flowId).then(() => { setAppLoaded(true); });
  //   }
  // }, [flowId]);

  return <Box sx={{
    textOverflow: 'wrap',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  }}
  >
    {props.children}
    {/* {appLoaded
      ? <DebugChatApp onClose={props.onClose} hideGraph />
      : <Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
        <CircularProgress size='5rem' />
      </Box>
    } */}
  </Box>;
}


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: '10px',
    top: '10px',
    border: `2px solid ${theme.palette.background.paper}`,
    zIndex: 9001,
  },
}));


export function ChatFABIcon(props: {
  pulse: boolean,
}): React.ReactElement {
  const { icon } = useStyling();

  const [animationData, setAnimationData] = useState<object | null>(null);

  useEffect(() => {
    if (icon?.lottieUrl) {
      const fetchData = async () => {
        try {
          const response = await fetch(icon.lottieUrl);
          const data = await response.json();
          console.log(data);
          setAnimationData(data);
        }
        catch (error) {
          console.error('Error loading animation:', error);
        }
      }

      fetchData();
    }
  }, [icon?.lottieUrl]);

  return animationData
    ? <Box><Lottie animationData={animationData} loop style={{ width: '100%', height: '100%' }} /></Box>
    : <Pulse disabled={!props.pulse}><SmsRoundedIcon sx={{ fontSize: 35 }} /></Pulse>
}



export function ChatAppPopover(props: {
  children: React.ReactElement,
  authToken?: string,
  showLoading?: boolean,
}): React.ReactElement {
  const [
    inConversation,
    unreadCount,
    setInConversation,
    clearUnreadCount,
  ] = useClientStore(useShallow(state => [
    state.inConversation,
    state.unreadCount,
    state.setInConversation,
    state.clearUnreadCount,
  ]));
  const [
    loading,
    loadIdentity,
  ] = useUserAndWorkspaceStore(useShallow(state => [
    state.isLoading(),
    state.loadIdentity,
  ]));
  const client = useApolloClient();
  const theme = useTheme();

  const closeApp = () => {
    clearUnreadCount();
    setInConversation(false);
  };
  const startApp = () => {
    if (!isDragging.current) {
      setInConversation(true);
      clearUnreadCount();
      if (autoOpen.current) {
        clearTimeout(autoOpen.current);
      }
      skipInitialOpen.current = true;
    }
  };

  const skipInitialOpen = useRef(false);
  const autoOpen = useRef(null)
  const { layout } = useStyling({
    onStylingLoaded: ({ openAfter }) => {
      if (skipInitialOpen.current) {
        return;
      }
      if (openAfter !== null) {
        autoOpen.current = setTimeout(() => {
          setInConversation(true);
        }, openAfter * 1000);
      }
      skipInitialOpen.current = true;
    },
  });

  const anchorEl = useRef<HTMLButtonElement>(null);
  const isDragging = useRef(false);
  const dragStartTimeout = useRef(null);
  const dragEndTimeout = useRef(null);

  const draggableProps = {
    onStart: () => {
      isDragging.current = false;
      if (dragStartTimeout.current) {
        clearTimeout(dragStartTimeout.current);
      }
      // delay drag start to make sure click is not omitted by tiny drag
      dragStartTimeout.current = setTimeout(() => {
        isDragging.current = true;
      }, 200);
    },

    onStop: () => {
      if (dragStartTimeout.current) {
        clearTimeout(dragStartTimeout.current);
      }
      if (dragEndTimeout.current) {
        clearTimeout(dragEndTimeout.current);
      }
      dragEndTimeout.current = setTimeout(() => {
        isDragging.current = false;
      }, 0);
    },
  };

  const showDialog = inConversation && anchorEl.current != null && !props.showLoading;

  useEffect(() => {
    loadIdentity(client, true);
  }, [props.authToken]);

  return <>
    {/* TODO somehow the draggable wrapper is causing the FAB to be unclickable on linkedin */}
    {/* <Draggable axis='y' {...draggableProps}> */}
    <Box
      height={layout.fabSize}
      width={layout.fabSize}
      borderRadius={layout.fabSize}
      position='fixed'
      bottom={layout.fabSpacing}
      right={layout.fabSpacing}
      sx={{ zIndex: 9000, opacity: showDialog ? 0 : 1 }}
    >
      <StyledBadge anchorOrigin={{ vertical: 'top', horizontal: 'left' }} badgeContent={unreadCount} color='error'>
        <Fab
          ref={anchorEl}
          disabled={loading || props.showLoading}
          color='secondary'
          sx={{ height: layout.fabSize, width: layout.fabSize }}
          onClick={startApp}
        >
          {!loading
            ? props.showLoading
              ? <CircularProgress size={35} />
              : <ChatFABIcon pulse={unreadCount !== 0} />
            : <PersonOffRoundedIcon sx={{ fontSize: 35 }} />}
        </Fab>
      </StyledBadge>
    </Box>
    {/* </Draggable> */}
    <Draggable {...draggableProps} disabled={!layout.dialogDraggable}>
      <Popover
        // MUI modal is actually created outside of the container DOM
        // to remove it from web content capture we need a separate element id for tracking
        // TODO use container props in global theme to force everything inside container DOM
        // https://chatgpt.com/share/8302c553-be8c-445d-a0a9-6bdb95442c70
        id={goPixieDrawerElementId}
        open={showDialog}
        anchorEl={anchorEl.current}
        onClose={closeApp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              borderRadius: 10,
              minHeight: 80,
              zIndex: 9002,
              background: `linear-gradient(to bottom right, ${alpha(theme.palette.primary.light, 0.1)}, ${alpha(theme.palette.secondary.light, 0.2)})`,
              backdropFilter: 'blur(10px)',
              height: layout.dialogHeight,
              width: layout.dialogWidth,
            }
          }
        }}
        keepMounted
      >
        <Badge
          badgeContent={<IconButton onClick={closeApp}><CloseRounded /></IconButton>}
          sx={{
            height: '100%',
            width: '100%',
            '& .MuiBadge-badge': {
              right: '12px',
              top: '12px',
            }
          }}
        >
          <ChatAppWithContainer onClose={closeApp}>
            {props.children}
          </ChatAppWithContainer>
        </Badge>
      </Popover>
    </Draggable>
  </>;
}
