import React, { useRef } from 'react'
import { Card, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { JSONInputField, ListEditView } from './common';
import { JSONSchema7, JSONSchema7Definition, JSONSchema7TypeName } from 'json-schema';
import { FlowParamInputType } from '../../../generated/gql/graphql';
import KnowledgeBaseSelect from './param-inputs/KnowledgeBaseSelect';
import DatasetSelect from './param-inputs/datasetSelect';
import JsonSchemaEditor from './param-inputs/json-schema';
import { getSchema } from './ParamEditor.utils';

import { DataRefPopoverProps, withDataRefPopover } from './DataRefPopover';
import { ArrayInputField } from './ArrayInputField';
import { toPydanticData, toStrawberryData } from '../../utils/pydantic';
import FileSelect from './param-inputs/fileSelect';
import { useUserAndWorkspaceStore } from '../../hooks/UserAndWorkspaceStore';

function emptyToString(val: any): any {
  if (val === null || val === undefined) return '';
  return val;
}

function undefinedToDefault(val: any, schemaDef: JSONSchema7Definition | undefined): any {
  if (val === undefined) {
    return getDefaultValue(schemaDef);
  }
  return val;
}


function isPropertyOptional(required: string[] | undefined, propertyName: string, propertySchemaDef: JSONSchema7Definition): boolean {
  return Boolean(getSchema(propertySchemaDef)?.default || !required?.includes(propertyName))
}


function getDefaultValue(schemaDef: JSONSchema7Definition | undefined): any {
  const schema = getSchema(schemaDef);
  if (!schema) return {}
  if (schema.default !== undefined) return schema.default;
  const targetType = schema.type || schema.anyOf?.[0];
  if (!targetType) return {};
  switch (targetType) {
    case true: return {};
    case "string": return "";
    case "number": return 0;
    case "boolean": return false;
    case "object": return schema.properties
      ? Object.entries(schema.properties)
        .reduce((ret, [k, t]) => {
          if (isPropertyOptional(schema.required, k, t)) return ret;
          return {
            ...ret,
            [k]: getDefaultValue(t)
          }
        }, {})
      : {};
    case "integer": return 0;
    case "array": return [];
    case "null": return null;
    default:
      if (Array.isArray(targetType)) {
        if (targetType.length == 0) return {};
        return getDefaultValue({ "type": targetType[0] });
      }
      return getDefaultValue(targetType);
  }
}


function SpecialParamInput(props: {
  schema: JSONSchema7,
  inputType: FlowParamInputType,
  value?: any,
  onChange: (val?: any) => void,
}): React.ReactElement {
  const siteId = useUserAndWorkspaceStore(state => state.workspaceId);

  if (props.schema.type == 'array') {
    return <ArrayInputField
      itemSchema={{ ...getSchema(props.schema.items), inputType: props.inputType }}
      value={(props.value || []) as any[]}
      onChange={props.onChange}
    // renderItem={item => <Card sx={{ p: 1 }}>
    //   <SpecialParamInput
    //     inputType={props.inputType}
    //     value={v}
    //     onChange={onChange}
    //   />
    // </Card>}
    />

  }
  switch (props.inputType) {
    case FlowParamInputType.KnowledgeBase:
      return <KnowledgeBaseSelect
        value={toStrawberryData(props.value)}
        onChange={v => props.onChange(toPydanticData(v))}
      />
    case FlowParamInputType.JsonSchema:
      return <>
        <JsonSchemaEditor
          schema={props.value || {}}
          onChange={props.onChange}
        />
      </>

    case FlowParamInputType.Dataset:
      return <DatasetSelect
        datasetId={props.value}
        siteId={siteId}
        onChange={props.onChange}
      />
    case FlowParamInputType.File:
      return <FileSelect
        fileId={props.value}
        onChange={props.onChange}
      />
    default:
      return <JSONInputField
        value={props.value}
        onChange={props.onChange}
      />
  }
}

// input UX for single-typed value
// array/anyOf/multiple type is not supported by this and should be handled somewhere else
export function ParamInputField(props: {
  schema: JSONSchema7 & { inputType?: FlowParamInputType } | undefined,
  value: any,
  onChange: (value: any) => void,
  dataRefProps?: DataRefPopoverProps,
}): React.ReactElement {
  const valueOrDefault = undefinedToDefault(props.value, props.schema);
  const dataRefProps: DataRefPopoverProps | undefined = props.dataRefProps;

  if (!props.schema) {
    return <JSONInputField
      value={valueOrDefault}
      onChange={v => props.onChange(v)}
    />
  }

  if (props.schema.inputType)
    return <SpecialParamInput
      schema={props.schema}
      inputType={props.schema.inputType}
      value={valueOrDefault}
      onChange={props.onChange}
    />

  if (props.schema.enum) {
    return <ToggleButtonGroupWithDataRef
      dataRefProps={dataRefProps}
      value={emptyToString(valueOrDefault)}
      onChange={(e, selected) => {
        if (selected === '') props.onChange(undefined);
        props.onChange(selected);
      }}
      fullWidth
      exclusive
    >
      {props.schema.enum.map((v, idx) => <ToggleButton
        key={idx}
        value={emptyToString(v)}
        sx={{ textTransform: 'capitalize' }}
      >
        {v?.toString()}
      </ToggleButton>)}
    </ToggleButtonGroupWithDataRef>
  }

  // treat as any when type not specified
  if (!props.schema.type && !props.schema.anyOf) {
    return <JSONInputField value={props.value} onChange={props.onChange} />
  }

  switch (props.schema.type as JSONSchema7TypeName) {
    case "boolean":
      return <SelectWithDataRef
        dataRefProps={dataRefProps && { ...dataRefProps, setInnerFocus: 'inputRef' }}
        value={props.value ? 'true' : 'false'}
        onChange={e => props.onChange(e.target.value == 'true')}
      >
        <MenuItem value='true'>true</MenuItem>
        <MenuItem value='false'>false</MenuItem>
      </SelectWithDataRef >
    case "number":
    case "integer":
      return <TextFieldWithDataRef
        dataRefProps={dataRefProps && { ...dataRefProps, setInnerFocus: 'inputRef' }}
        type='number'
        value={emptyToString(valueOrDefault)}
        onChange={e => props.onChange(+e.target.value)}
      />
    case "string":
      return <TextFieldWithDataRef
        dataRefProps={dataRefProps && { ...dataRefProps, setInnerFocus: 'inputRef' }}
        multiline
        maxRows={10}
        value={emptyToString(valueOrDefault)}
        onChange={e => props.onChange(e.target.value)}
      />
    case "array":
      // no longer support array rendering field here. Should be implemented separately in paramEditor
      // // TODO not safe here, works for the basic case only
      const itemSchema = getSchema((props.schema as JSONSchema7).items as JSONSchema7Definition);
      return <ArrayInputField
        itemSchema={itemSchema}
        value={valueOrDefault}
        onChange={props.onChange}
      />
    case 'object':
      if (props.schema.additionalProperties) return <JSONInputField value={props.value} onChange={props.onChange} />;
    case 'null':
    default:
      return <></>;
  }
}

const ToggleButtonGroupWithDataRef = withDataRefPopover(ToggleButtonGroup);
const SelectWithDataRef = withDataRefPopover(Select);
const TextFieldWithDataRef = withDataRefPopover(TextField)
