import {
  Edge,
  MarkerType,
  XYPosition,
} from 'reactflow';
import 'reactflow/dist/style.css';
import {
  toFlowNode,
} from '../features/Pixie/Editor/FlowNode';
import {
  IFlowConfig,
  TFlowConfig,
  TFlowNodePositionV2,
  TFlowPluginV2,
} from '../../generated/gql/graphql';
import {
  FlowNodeData,
  GraphNode,
  flowNodeType,
} from '../types/GraphNode';
import { removeTypename } from './removeTypename';
import { ParameterInput } from '../types/ParameterInput';
import { arrayToObject } from './common';
import { Selection } from '../types/Selection';

export function getEdgeStyle(highlightColor?: string): React.CSSProperties {
  return highlightColor
    ? {
      strokeWidth: 8,
      stroke: highlightColor,
    }
    : {
      strokeWidth: 5,
      stroke: '#777',
    };
}


export function createEdge(
  sourceId: string,
  targetId: string,
  sourceHandle: string,
  data: ParameterInput,
): Edge<ParameterInput> {
  return {
    id: `${sourceId}-${targetId}-${sourceHandle}`,
    type: 'smoothstep',
    pathOptions: {
      offset: 50,
      borderRadius: 75,
    },
    source: sourceId,
    target: targetId,
    sourceHandle: sourceHandle,
    targetHandle: 'prev',
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#333',
      width: 10,
    },
    data,
    style: getEdgeStyle(),
  }
}


export function getGraphForZustand(
  flow: TFlowConfig,
  layout: TFlowNodePositionV2[],
): {
  nodesData: { [id: string]: FlowNodeData },
  nodesLocations: { [id: string]: XYPosition },
  edges: { [id: string]: Edge }
} {
  const nodesData = arrayToObject(flow.plugins, "id");
  const edges = arrayToObject(flow.edges.map(
    edge => createEdge(
      edge.sourceId,
      edge.targetId,
      edge.branchId,
      { params: edge.params, dynamicParams: edge.dynamicParams },
    )
  ), "id")
  const nodesLocations = arrayToObject(layout, 'id')
  return { nodesData, nodesLocations, edges };
}


export function createFlowFromZustand(nodesData: FlowNodeData[], edges: Edge<ParameterInput>[]): IFlowConfig {
  const ret: IFlowConfig = {
    edges: edges.map(e => ({
      sourceId: e.source,
      targetId: e.target,
      branchId: e.sourceHandle!,
      params: e.data?.params,
      dynamicParams: e.data?.dynamicParams,
    })),
    plugins: nodesData.map(n => ({
      id: n.id,
      pluginType: n.pluginType,
      params: n.params || {},
      dynamicParams: n.dynamicParams === undefined ? {} : n.dynamicParams,
      displayName: n.displayName,
      inProgressMessage: n.inProgressMessage,
    }))
  };
  return removeTypename(ret);
}

export function createNodes(
  nodesData: { [id: string]: FlowNodeData; },
  nodesLocations: { [id: string]: XYPosition; },
  selections: Selection[] | Selection | null
): GraphNode[] {
  const normalizedSelections = selections === null ? [] : Array.isArray(selections) ? selections : [selections];
  return Object.values(nodesData).map(
    n => ({
      id: n.id,
      data: n,
      position: nodesLocations[n.id] || { x: 0, y: 0 },
      type: flowNodeType,
      selected: normalizedSelections.some(s => s.type === 'node' && s.id === n.id),
    })
  );
}

export function createEdges(
  edges: { [id: string]: Omit<Edge, 'style' | 'selected'>; },
  selections: Selection[] | Selection | null,
  highlightColor: string = 'purple'
): Edge[] {
  const normalizedSelections = selections === null ? [] : Array.isArray(selections) ? selections : [selections];
  const isSelected = (e: Edge) => normalizedSelections.some(s => s.type === 'edge' && s.id === e.id);

  return Object.values(edges).map(
    e => ({
      ...e,
      style: getEdgeStyle(isSelected(e) ? highlightColor : undefined),
      selected: isSelected(e),
    })
  );
}
