import { useTheme, Stack, Box, IconButton, Button, Typography, LinearProgress } from "@mui/material";
import { JSONSchema7 } from "json-schema";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { FlowParamInputType } from "../../../generated/gql/graphql";
import JsonView from "react18-json-view";
import 'react18-json-view/src/style.css'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Icon } from "@iconify/react";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';


const ParamEditor = lazy(() => import('./ParamEditor'));

export function ArrayInputField<T>(props: {
  itemSchema: JSONSchema7 & { inputType?: FlowParamInputType } | undefined,
  value: T[] | null | undefined,
  onChange: (value: T[] | null | undefined) => void,
  renderItem?: (item: T) => React.ReactElement,
}): React.ReactElement {
  const theme = useTheme();
  const values: any[] = Array.isArray(props.value) ? props.value : [];

  const [editingItem, setEditingItem] = useState<number | null>(null);

  const updateItem = (val, idx) => {
    const newVals = [...values];
    newVals[idx] = val;
    props.onChange(newVals);
  }

  useEffect(() => {
    setEditingItem(null);
  }, [props.itemSchema]);

  console.log('in array input field', props.itemSchema)

  if (editingItem !== null) {
    return <Stack spacing={1} pb={4}>
      <Stack direction='row' spacing={1} display='flex' alignItems='center'>
        <IconButton color="primary" onClick={() => setEditingItem(null)}><ArrowBackIosRoundedIcon fontSize="small" /></IconButton>
        <Typography variant="subtitle2">Editing item [{editingItem}]</Typography>
      </Stack>

      {props.itemSchema && <Suspense fallback={<LinearProgress />}>
        <ParamEditor
          schema={props.itemSchema}
          value={values[editingItem]}
          onChange={v => updateItem(v, editingItem)}
          resetTrigger={editingItem}
          dynamicDisabled
        />
      </Suspense>}
    </Stack>
  }

  return <Stack spacing={1} pb={4}>
    {values.map((v, idx) => <Stack
      direction='row'
      key={idx}
      spacing={2}
      p={1}
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box flexGrow={1}>
        {props.renderItem?.(v) ||
          <JsonView
            src={v}
            enableClipboard={false}
            collapsed={1}
          />
        }
      </Box>
      <Stack direction='row' fontSize='12px'>
        <IconButton size="small" onClick={() => setEditingItem(idx)}><Icon icon='bx:edit' /></IconButton>
        <IconButton size="small" color='error' onClick={() => {
          props.onChange(values.filter((_, i) => i !== idx))
        }}><DeleteOutlineRoundedIcon fontSize="inherit" /></IconButton>
      </Stack>
    </Stack>)}

    <Button variant="outlined" color='success' size="small" onClick={() => setEditingItem(values.length)}><AddRoundedIcon /></Button>
  </Stack>
}
