import { Theme, ThemeProvider, alpha, createTheme, responsiveFontSizes } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { defaultTheme } from './themes';
import { GET_STYLING } from '../../graphql/query';
import { GetStylingQuery, TFont, TIcon, TLayout, TPalette, TStyling } from '../../../generated/gql/graphql';
import { useClientStore } from '../../hooks/ClientState';
import { useQuery } from '@apollo/client';
import Color from 'color';
import { isEqual } from 'lodash';

const defaultLayout: TLayout = {
  fabSize: 80,
  fabSpacing: { xs: 40, sm: 60, md: 80 },
  dialogWidth: { xs: '100vw', md: '60vw', lg: '40vw', xl: '30vw' },
  dialogHeight: '70vh',
  dialogDraggable: false,
}

function getTheme(palette: TPalette, font: TFont): Theme {

  const augmentedPrimary = defaultTheme.palette.augmentColor({ color: { main: palette.primary } })

  const theme = responsiveFontSizes(createTheme(
    {
      typography: {
        fontSize: font.fontSize,
        fontFamily: font.fontFamily,
      },
      palette: {
        background: {
          default: augmentedPrimary.main,
          paper: augmentedPrimary.light,
        },
        // NOTE: we are swapping primary and secondary colors here due to how it's currently used in the app
        primary: defaultTheme.palette.augmentColor({ color: { main: palette.accent2 } }),
        secondary: defaultTheme.palette.augmentColor({ color: { main: palette.accent1 } }),
        info: defaultTheme.palette.augmentColor({ color: { main: palette.callToAction } }),
        text: {
          primary: alpha(augmentedPrimary.contrastText, 0.87),
          secondary: alpha(augmentedPrimary.contrastText, 0.6),
          disabled: alpha(augmentedPrimary.contrastText, 0.38),
        }
      },
    }))
  return theme;
}


function standardizePalette(palette: TPalette): TPalette {
  return {
    primary: Color(palette.primary).hex(),
    accent1: Color(palette.accent1).hex(),
    accent2: Color(palette.accent2).hex(),
    callToAction: Color(palette.callToAction).hex(),
  };
}


export type StylingWithDefaults = {
  palette: TPalette,
  font: TFont,
  layout: TLayout,
  openAfter: number | null,
  icon: TIcon | null,
}

export function getDefaultStyling(): StylingWithDefaults {
  const defaultTheme = createTheme();
  const palette: TPalette = {
    primary: defaultTheme.palette.background.default,
    accent1: defaultTheme.palette.secondary.main,
    accent2: defaultTheme.palette.primary.main,
    callToAction: defaultTheme.palette.info.main,
  };
  return {
    palette: standardizePalette(palette),
    font: {
      fontSize: defaultTheme.typography.fontSize,
      fontFamily: defaultTheme.typography.fontFamily,
    },
    layout: defaultLayout,
    openAfter: null,
    icon: null,
  };
}


export function useStyling(props?: {
  onStylingLoaded?: (styling: StylingWithDefaults) => void,
}): StylingWithDefaults & {
  isDefaultPalette: boolean,
  isDefaultFont: boolean,
  isDefaultLayout: boolean,
  loading: boolean,
  refetch: () => void,
} {
  const flowId = useClientStore(state => state.flowId);
  const [styling, setStyling] = React.useState(getDefaultStyling());


  const { loading, refetch } = useQuery(GET_STYLING, {
    variables: { flowId },
    onCompleted: (data: GetStylingQuery) => {
      const updatedStyling = getDefaultStyling();
      if (data.styling.palette) {
        updatedStyling.palette = standardizePalette(data.styling.palette);
      }
      if (data.styling.font) {
        updatedStyling.font = data.styling.font;
      }
      if (data.styling.layout) {
        updatedStyling.layout = data.styling.layout;
      }
      if (data.styling.icon) {
        updatedStyling.icon = data.styling.icon;
      }
      updatedStyling.openAfter = data.styling.openAfter === undefined ? null : data.styling.openAfter;
      setStyling(updatedStyling);
      props?.onStylingLoaded?.(updatedStyling);
    },
    skip: !flowId,
    // NOTE: this flag is necessary for triggering onCompleted on refetch
    // https://github.com/apollographql/apollo-client/issues/11151
    notifyOnNetworkStatusChange: true,
  });

  return {
    palette: styling.palette,
    font: styling.font,
    layout: styling.layout,
    openAfter: styling.openAfter,
    icon: styling.icon,
    isDefaultPalette: isEqual(styling.palette, getDefaultStyling().palette),
    isDefaultFont: isEqual(styling.font, getDefaultStyling().font),
    isDefaultLayout: isEqual(styling.layout, getDefaultStyling().layout),
    loading,
    refetch,
  };
}


export default function CustomizationProvider(props: {
  children: React.ReactNode,
  palette?: TPalette,
  font?: TFont,
  // only tracks palette change when loading from server
  onStylingLoaded?: (styling: StylingWithDefaults) => void,
}): React.ReactElement {
  const { palette, font } = useStyling({ onStylingLoaded: props.onStylingLoaded });

  return <ThemeProvider theme={getTheme(props.palette || palette, props.font || font)}>
    {props.children}
  </ThemeProvider>
}
