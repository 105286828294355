import React, { useEffect } from 'react';
import { AppContainer } from '../../../../components/pixie/AppContainer';
import { ExternalGraphQLClientProvider } from './ExternalGraphQLClientProvider';
import CustomizationProvider, { StylingWithDefaults } from '../../../../components/customization/customization-provider';
import { TFont, TLayout, TPalette } from '../../../../../generated/gql/graphql';
import { ChatAppPopover } from '../Web/ChatAppPopover';
import { useClientStore } from '../../../../hooks/ClientState';
import { ChatApp } from '../Web/ChatApp';
import { useShallow } from 'zustand/react/shallow';


export interface EmbeddedChatAppProps {
  authToken?: string;
  useLocalhost?: boolean;
  palette?: TPalette;
  font?: TFont;
  layout?: TLayout;
  onStylingLoaded?: (styling: StylingWithDefaults) => void;
}

export function EmbeddedChatApp(props: EmbeddedChatAppProps): React.ReactElement {
  const httpUrl = props.useLocalhost ? 'http://localhost:8000/graphql' : 'https://gopixie.ai/graphql';
  const wsUrl = props.useLocalhost ? 'ws://localhost:8000/graphql' : 'wss://gopixie.ai/graphql';
  const flowId = useClientStore(state => state.flowId);

  const [
    initializing,
    inConversation,
  ] = useClientStore(useShallow(state => [
    state.initializing,
    state.inConversation,
  ]));

  return <ExternalGraphQLClientProvider httpUrl={httpUrl} wsUrl={wsUrl} authToken={props.authToken}>
    <CustomizationProvider palette={props.palette} font={props.font} onStylingLoaded={props.onStylingLoaded}>
      <AppContainer>
        <ChatAppPopover authToken={props.authToken} showLoading={!flowId}>
          {/* Do not start app run until the dialog is first opened */}
          <ChatApp flowId={flowId} skip={initializing && !inConversation} />
        </ChatAppPopover>
      </AppContainer>
    </CustomizationProvider>
  </ExternalGraphQLClientProvider>;
}

// TODO option to use localStorage instead of sessionStorage
export function EmbeddedChatAppWithMemory(props: EmbeddedChatAppProps): React.ReactElement {

  const flowId = useClientStore(state => state.flowId);
  const setClientId = useClientStore(state => state.setClientId);

  useEffect(() => {
    const cid = sessionStorage.getItem(flowId);
    if (cid) {
      setClientId(cid);
    }
  }, [flowId]);

  useEffect(() => {
    const unsub = useClientStore.subscribe(
      state => [state.clientId, state.flowId],
      ([clientId, flowId]) => {
        if (flowId && clientId) {
          sessionStorage.setItem(flowId, clientId);
        }
      },
    );
    return unsub;
  }, []);

  return <EmbeddedChatApp {...props} />;
}
